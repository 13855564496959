import {
  BaseViewModel,
  getAllowedColumns,
  IAllowedColumns
} from '../../../../../common';
import { getLogger } from 'aurelia-logging';
import { ControllerService } from '../../../../../../services';
import { IAsyncEntity } from '../../../../../../types';
import { IController } from '../../../../../../interfaces';
import { ensureNumber, orderByPredicate } from '../../../../../../utility';
import { autoinject } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { routeNames } from '../../../../../../config';
import './sitedetailscontrollerlist.css';
import { getSession } from '../../../../../../config/sessionService';

interface ISiteDetailsControllerList {
  controllers: IAsyncEntity<IController[]>;
  columns: IAllowedColumns;
}

@autoinject()
export class SiteDetailsControllerList extends BaseViewModel<
  ISiteDetailsControllerList
> {
  constructor(
    private controllerService: ControllerService,
    private router: Router
  ) {
    super(getLogger('sitedetailscontrollerlist'));
  }

  activate(params: { id: string }) {
    this.attachMapState(this.mapState(ensureNumber(params.id)));
  }

  mapState = (siteId: number) => () => ({
    controllers: this.controllerService
      .getControllersBySiteId(siteId)
      .map(controllers =>
        // #3504: Hide all remote controllers:
        orderByPredicate(
          controllers.filter(controller => controller.siteId === siteId),
          c => c.alias,
          'asc'
        )
      )
      .getAsyncEntity(),
    // site => getAsyncEntitiesByAsyncArray(getAsyncEntity(state.controllers.nativeControllersForSites, site.siteId), state.controllers.byId)),
    columns: getAllowedColumns(getSession())
  });

  navigateToController({ controllerId }: IController) {
    this.router.navigateToRoute(routeNames.sitedetailsControllerDetails, {
      controllerId
    });
  }
}
